import { MessageType } from '../translation';

export const messageFr: MessageType = {
  design: {
    sponsored: 'Sponsorisé',
    currency: {
      symbol: '€',
      test: 'Euro',
    },
    viewer: {
      next: 'suivant',
      prev: 'précédent',
      image: 'image',
    },
    ad: { textContent: 'Publicité' },
    lastUpdate: 'Dernière mise à jour',
    version: 'Version',
    last7days: '(7 derniers jours)',
    downloads: 'Téléchargements',
    andSeparator: 'et',
    product: {
      headingContent: { releaseDate: 'Date de sortie : ' },
      toProduct: 'Voir le produit',
      readTest: 'Lire le test',
      datasheet: 'Fiche technique',
      resume_datasheet: 'Résumé',
      testContent: {
        titleComplement: 'par {{complement}} le {{publicationDate}}',
        publicationDate: 'Publié le',
        link: 'Lire le test complet',
      },
      previewContent: {
        titleComplement: 'par {{complement}} le {{publicationDate}}',
        link: 'Lire la preview',
      },
      bestPrice: { seller: 'Marchand', variant: 'Support', price: 'Prix' },
      similarItems: { displayMore: 'Voir plus de jeux similaires', displayLess: 'Voir moins de jeux similaires' },
      date: {
        year: 'Courant {{year}}',
        quarter1: '1er trimestre',
        quarter2: '2ème trimestre',
        quarter3: '3ème trimestre',
        quarter4: '4ème trimestre',
        unknown: 'Non communiqué',
        releaseLabel: 'Sortie : ',
      },
    },
    productItem: {
      summaryAnchorTitle: 'Résumé',
      summaryTitle: 'Présentation',
      testAnchorTitle: 'Test',
      previewAnchorTitle: 'Preview',
      testTitle: 'Tests & Preview',
      newsAnchorTitle: 'Actualités',
      bestPrice: 'Meilleurs prix',
      readMore: 'Lire la suite',
      similarItem: 'Les jeux similaires',
      dataSheetTitle: 'Fiche technique {{title}}',
      fullReviewTitle: 'Toutes les informations sur {{title}}',
      latestNewsTitle: 'Les dernières news sur {{title}}',
      buyingGuidesTitle: "Nos guides d'achat",
      latestDealsTitle: 'Les derniers bons plans',
      latestDealsLink: 'Voir tous les bons plans',
      latestVideoTitle: 'Les dernières vidéos sur {{title}}',
      mostExpectedGames: 'Les jeux les plus attendus',
      forumAnchorTitle: 'Forum',
      videoAnchorTitle: 'Vidéos',
      buyingGuidesAnchorTitle: "Guides d'achat",
    },
    homepage: {
      latestDealsTitle: 'Les derniers bons plans',
      latestDealsLink: 'Voir tous les bons plans',
      latestNewsTitle: 'Dernières actualités',
      latestTestsTitle: 'Les derniers tests',
      latestTestsLink: 'Voir tous les tests',
      latestVideoTitle: 'Les dernières vidéos',
      latestVideoLink: 'Voir toutes les vidéos',
      mostExpectedGames: 'Les jeux les plus attendus',
    },
    itemAuthor: {
      latestNewsTitle: 'Dernières publications',
      presentation: 'Présentation',
    },
    itemDefault: {
      bottomInfo: 'Modifié le',
      'affiliate-1':
        "Cet article contient des liens d'affiliation, ce qui signifie qu'une commission peut être reversée à JVFR.",
      'affiliate-2': "Les prix mentionnés sont susceptibles d'évoluer.",
      latestDealsTitle: 'Les derniers bons plans',
      latestDealsLink: 'Voir tous les bons plans',
      latestNewsTitle: 'Dernières actualités',
      latestTestsTitle: 'Les derniers tests',
      latestTestsLink: 'Voir tous les tests',
      commentsList: 'Commentaires via Clubic',
      goToProduct: 'Voir la fiche',
      index: 'Sommaire',
    },
    boolean_checkbox: {
      yes: 'Oui',
      no: 'Non',
      all: 'Tous',
    },
    feedback: { altText: 'Donnez-nous votre avis sur JVFR', mainText: 'Donnez-nous votre avis' },
    mostExpectedGamesSlider: { title: 'Les jeux les plus attendus' },
    latestVideosSlider: { title: 'Les dernières vidéos', link: 'Voir toutes les dernières vidéos' },
    latestTestSlider: { title: 'Les derniers tests', link: 'Voir tous les tests' },
    ratingBlock: {
      conclusion: 'Conclusion',
      pros: 'Les plus',
      cons: 'Les moins',
      cta: 'Voir le prix',
      generalRating: 'Note générale',
      subRating: 'Sous-notes',
      bestPrices: 'Meilleurs prix',
    },
    heroCard: { cta: 'Voir le prix', conclusion: 'Lire la conclusion' },
    largeCard: {
      cta: "Voir l'offre",
      test: 'Voir le test',
      seeProduct: 'Voir la fiche',
      nothingToDisplay: "Ce produit n'a pas encore été testé",
    },
    serviceCardComparative: { cta: 'Essayer {{title}} maintenant !', test: 'Lire le test complet' },
    miniCard: {
      cta: "Voir l'offre",
      test: 'Lire le test',
      download: 'Télécharger',
      defaultLabels: {
        default: "Voir l'offre",
        product: 'Voir la fiche',
        download: 'Télécharger',
        service: "Voir l'offre",
      },
    },
    dataSheet: {
      name: 'Fiche technique',
      seeOffer: "Voir l'offre",
    },
    priceCard: {
      seeOffer: "Voir l'offre",
      getMorePrice: 'Voir plus de prix',
      getLessPrice: 'Voir moins de prix',
    },
    refurbished: 'reconditionné',
    reviewCard: {
      conclusion: 'Conclusion',
      test: 'Lire le test',
      bestPrice: 'Meilleurs prix',
      seeOffer: "Voir l'offre",
      getMorePrice: 'Voir plus de prix',
      getLessPrice: 'Voir moins de prix',
      noResultPrice: 'Aucun prix trouvé sur ce produit.',
      disclaimer: "Ce produit n'a pas encore été testé par notre équipe",
    },
    onlineServiceCard: { cta: "Voir l'offre", test: 'Voir le test', until: "Jusqu'à" },
    planAlternative: { moreDetails: "Détails de l'offre" },
    card: { readMore: 'Voir plus' },
    embed: {
      affilizz: {
        cta: {
          label: "Voir l'offre chez {{shopName}} à {{price}} €",
          promoCodePrime: 'PRIME',
          see_offer: "Voir l'offre",
        },
        box: {
          see_more: 'Voir toutes les offres',
          see_less: "Voir moins d'offres",
        },
      },
    },
    published: 'Publié le',
    by: 'par',
    listingBox: { sponsoredMessage: 'Contenu sponsorisé' },
    reset: 'Ré-initialiser',
    slider: {
      from: 'De',
      to: 'à',
      unlimited: 'Illimité',
    },
    select: {
      all: 'Tous',
    },
    'results-order': {
      results: 'résultats',
      orderBy: 'Trier par',
    },
    download: {
      free: 'Télécharger gratuitement',
      continue: 'Continuer mon téléchargement de',
      partner: 'Partenaire',
      auto: 'Votre téléchargement démarrera automatiquement dans {{seconds}}s...',
      start: 'Votre téléchargement a démarré !',
      thanks: "Merci d'avoir téléchargé sur Clubic.com",
      notStarted: 'Si votre téléchargement ne démarre pas',
      checkBy: 'Vérifié par',
      notFound: 'Vous ne le trouvez pas ?',
      checkDownload: 'Vérifiez la liste des téléchargements (Ctrl+ J) ou les autorisations de votre navigateur',
      clickHere: 'cliquez ici',
      needInformations: "Besoin d'information ? Vous avez rencontré un problème lors du téléchargement ?",
      report: 'Signalez-le nous',
      check: 'ou consultez la',
      dedicatedPage: 'page dédiée',
      modalSuccess: {
        title: 'Votre téléchargement est prêt !',
        description: 'Si le téléchargement ne démarre pas automatiquement,',
        stepOne: 'Attendez que le programme soit téléchargé',
        stepTwo: "Lancez l'installateur et suivez les instructions",
      },
      for: 'pour',
      download: 'Télécharger',
      downloadFor: 'Télécharger pour {{os}}',
      freeVersion: 'Version gratuite',
      guide: {
        header: 'À découvrir',
      },
      update: 'Mise à jour',
      sponsored: 'Sponsorisé',
    },
    newsletter: {
      description: "Recevez chaque jour le meilleur de l'actu tech dans votre boîte mail",
      error: "Erreur lors de l'inscription",
      placeholder: 'Votre adresse email',
      register: 'S’inscrire',
      success:
        "Votre inscription a bien été prise en compte. N'oubliez pas de vérifier votre boîte mail pour confirmer l'inscription.",
      title: 'Inscrivez-vous à notre newsletter quotidienne !',
    },
    communityBloc: {
      title: 'Rejoignez la communauté Clubic',
      description:
        'Rejoignez la communauté des passionnés de nouvelles technologies. Venez partager votre passion et débattre de l’actualité avec nos membres qui s’entraident et partagent leur expertise quotidiennement.',
      btn: "S'inscrire / Connexion",
    },
    subscribe: {
      user: 'Vous êtes un utilisateur de ',
      gnews: 'Google Actualités',
      whatsapp: 'WhatsApp',
      or: ' ou de ',
      follow: "Suivez-nous pour ne rien rater de l'actu tech !",
    },
    sponsorBloc: {
      description: 'Article proposé et conçu par Clubic en partenariat avec ',
    },
    videoBloc: {
      title: 'A découvrir en vidéo',
    },
    reassurance: {
      description:
        "L'équipe Clubic sélectionne et teste des centaines de produits qui répondent aux usages les plus courants, avec",
      descriptionB: 'le meilleur rapport qualité / prix possible.',
      top1: 'Indépendance',
      top2: 'Transparence',
      top3: 'Expertise',
    },
    googleBloc: {
      description: '',
    },
    readMoreBloc: {
      read_more_label: 'Voir plus',
    },
    sortable: {
      '__sortable-price__': 'Prix',
      '__sortable-popularity__': 'Popularité',
      '__sortable-popularity-dl__': 'Nombre de téléchargement',
      '__sortable-smart-popularity__': 'Popularité',
      '__sortable-updated-at__': 'Date de mise à jour',
      '__sortable-released-at__': 'Date de sortie',
      '__ordering-asc__': 'croissant',
      '__ordering-desc__': 'décroissant',
    },
    inform: {
      sponsored: {
        introduction: 'Article proposé et conçu par Clubic en partenariat avec',
        read: 'Lire',
        legal: 'la charte de confiance',
      },
      p2p: {
        warning: 'Avertissement',
        description:
          "Bien que le téléchargement (direct ou en P2P) ne soit pas illégal en soi, il est interdit de télécharger des œuvres protégées par le droit d'auteur ou d’autres droits de propriété intellectuelle sans l’autorisation des titulaires de ces droits. Clubic recommande expressément aux utilisateurs de son site et aux tiers de respecter scrupuleusement ces droits ; étant rappelé qu’en France la violation des droits d’auteurs est constitutive du délit de contrefaçon puni d’une peine de 300 000 euros d’amende et de 3 ans d'emprisonnement (art. L. 335-2 s. CPI) et qu’il existe également des sanctions spécifiques en cas de contournement de mesures techniques de protection (art. L. 335-3-1 et L.335-3-2 CPI). En outre, le titulaire de l’abonnement à internet doit veiller à l’usage licite de sa connexion, sauf à s’exposer au risque de contravention de négligence caractérisée sanctionnée par une peine d’amende de 1 500 € pour les personnes physiques. En cas d’utilisation d’un logiciel P2P pour télécharger ou mettre à disposition des œuvres protégées par le droit d’auteur, sans autorisation, le titulaire de la connexion à internet pourra être destinataire de recommandations de l’Arcom et, en cas de constats répétés, être poursuivi sur le fondement de la contravention de négligence caractérisée.",
        linkText: "Plus d'informations sur le téléchargement illégal",
      },
      affiliation: {
        description:
          "Nos experts recommandent des produits et services technologiques en toute indépendance. Si vous achetez via un lien d’affiliation, nous pouvons gagner une commission. Les prix mentionnés sont susceptibles d'évoluer.",
        linkText: 'En savoir plus',
      },
    },
    sectionInsert: {
      defaultViewMore: 'Tous les {{sectionTitle}}',
    },
    postItemLine: {
      authorLabel: 'par {{author}}',
      commentLabel: 'commentaire',
    },
    sectionSelection: {
      defaultTitle: 'Top Sections',
    },
  },
};
